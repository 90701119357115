import {useAsyncCurrentUserUsername} from "~/composables/useDatabaseValues.js";

export default defineNuxtRouteMiddleware(async to => {
    const {data: username} = await useAsyncCurrentUserUsername();

    if (!username.value) {
        // redirect to the login page
        return navigateTo({
            path: '/login',
            query: {
                // keep the original path as a query parameter
                redirect: to.fullPath,
            },
        })
    }
})